<template>
  <div class="about">
    <header-v />

    <div class="about_wrapper">
      <div class="about_banner">
        <img src="@/assets/imgs/bg_011111.jpg" alt="" />
      </div>
      <div class="body__content_box">
        <div class="company_content_box d-flex justify-content-between align-items-center">
          <div class="company_image_box"><img src="@/assets/imgs/about_us.jpg" alt=""></div>
          <div class="flex-1 company_introduce text-lg text-grey d-flex flex-column justify-content-between">
            <h2 class="company_title">公司简介</h2>
            <div class="">
              <p class="text-indent">成都天府新区人才发展职业培训学校（以下简称“天府人才学校”）是成都天投集团旗下从事人才发展与教育培训的专业服务机构，致力于为党政机关、街道、企事业单位和个人提供一站式线上线下优质培训服务及人才发展解决方案。</p>
              <p class="text-indent">天府人才学校充分融合国家级新区发展优势与国有企业资源禀赋，整合国内外高校、智库机构、标杆企业等资源，汇聚专业师资队伍，打造特色课程体系，建立起党政人才培训、企业人才培训、职业技能培训三大核心业务。</p>
           </div>
          </div>
        </div>

        <div class="business_intro_wrapper d-flex justify-content-between align-items-center">
          <div class="flex-1 business_intro_introduce text-lg text-grey d-flex flex-column justify-content-between">
            <h2 class="business_intro_title">党政人才培训</h2>
            <p class="text-indent">
              党政人才培训主要依托国有企业资源，服务于各政府机关、街道和企事业单位，开展党政类培训工作和专业服务。培训以问题解决为导向，以满足组织培养需求、工作岗位需求和个人发展需求为目标，遵循学员成长和学习规律来设计培训课程和组织培训教学，目前已形成了系统科学、内容丰富、特色鲜明的课程体系。课程涵盖理想信念主题教育、党性培训、党务工作培训，政策精神解读政府职能建设、公职人员素质提升、高校定制专题培训班等。
           </p>
          </div>
          <div class="business_intro_image_box"><img src="@/assets/imgs/rcpx.jpg" alt=""></div>
        </div>

        <div class="business_intro_wrapper d-flex justify-content-between align-items-center">
          <div class="business_intro_image_box"><img src="@/assets/imgs/qypx.jpg" alt=""></div>
          <div class="flex-1 business_intro_introduce text-lg text-grey d-flex flex-column justify-content-between">
            <h2 class="business_intro_title">企业人才培训</h2>
            <p class="text-indent">
              企业人才培训依托成都天府新区投资集团资源禀赋，广泛结合国内知名院校，国内外知名机构，建立了具有前瞻性、时效性、针对性的特色课程体系，涵盖党建、公开课、内训、标杆考察、论坛沙龙、拓展、E-Learning等培训内容，以专业优质的服务，为广大企事业单位的人才开发与培养提供专业支持。
           </p>
          </div>
        </div>

        <div class="business_intro_wrapper d-flex justify-content-between align-items-center">
          <div class="flex-1 business_intro_introduce text-lg text-grey d-flex flex-column justify-content-between">
            <h2 class="business_intro_title">职业技能培训</h2>
            <p class="text-indent">
              职业技能培训以提升人才专业化和职业化水平，增强企业人力资本竞争力为主要目标，紧紧围绕成都先进制造业、现代服务业和新经济“5+5+1”重点领域，产业园区和重点企业对技能人才的需求，提供职业能力测评、职业技能培训和职业技能鉴定一体化服务。
           </p>
          </div>
          <div class="business_intro_image_box"><img src="@/assets/imgs/zyjnpx.jpg" alt=""></div>
        </div>


      </div>

    </div>
    <footer-c />
  </div>
</template>
<script>
import HeaderV from '@/components/Header'
import FooterC from '../../components/FooterC'

export default {
  name: 'About',
  components: {
    HeaderV,
    FooterC,
  },
}
</script>

<style lang="less" scoped>
.about_wrapper {
  .about_banner {
    height: 480px;
    max-height: 520px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    img {
      width: 100%;
    }
  }
  .body__content_box {
    padding-bottom: 100px;
  }

  .company_content_box {
    height: auto;
    .company_image_box {
      width: 580px;
      height: 380px;
      overflow: hidden;
    }
    .company_introduce {
      padding-left: 100px;
      line-height: 32px;
      height: 380px;
      .company_title {
        line-height: 40px;
      }
      &>div{
        text-align: justify;
        p.text-indent {
          text-indent: 2em;
          &:nth-of-type(1) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  .business_intro_wrapper {
    margin-top: 100px;
    .business_intro_image_box {
      width: 580px;
      height: 320px;
      overflow: hidden;
    }
    .business_intro_introduce {
      line-height: 32px;
      height: 320px;
      text-align: justify;
      .business_intro_title {
        line-height: 40px;
      }
    }

    &:nth-child(odd) {
      .business_intro_introduce {
        padding-left: 100px;
      }
    }
    &:nth-child(even) {
      .business_intro_introduce {
        padding-right: 100px;
      }
    }
  }
}
</style>